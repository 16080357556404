// components/Modal.tsx
import clsx from 'clsx';
import { CSSProperties } from 'react';
import { Modal as ModalLib } from 'react-overlays';

export enum MODAL_SIZES {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum MODAL_ALIGNMENT {
  DEFAULT = 'default',
  TOP = 'top',
}

export enum CLOSE_BUTTON_STYLE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  FOOTER = 'footer',
}

interface ModalProps {
  show: boolean;
  close: () => void;
  children: React.ReactNode;
  size?: MODAL_SIZES;
  alignModalContent?: MODAL_ALIGNMENT;
  showCloseButton?: boolean;
  closeButtonStyle?: CLOSE_BUTTON_STYLE;
  className?: string;
  style?: CSSProperties;
}

export function Modal({
  show,
  close,
  children,
  size = MODAL_SIZES.MD,
  alignModalContent = MODAL_ALIGNMENT.DEFAULT,
  showCloseButton = false,
  closeButtonStyle = CLOSE_BUTTON_STYLE.PRIMARY,
  className,
  style,
}: ModalProps) {


  const sizeClass = {
    [MODAL_SIZES.SM]: 'max-w-sm',
    [MODAL_SIZES.MD]: 'max-w-md',
    [MODAL_SIZES.LG]: 'max-w-3xl',
    [MODAL_SIZES.XL]: 'max-w-5xl',
  }[size];

  const alignClass =
  alignModalContent === MODAL_ALIGNMENT.TOP
    ? 'items-start pt-20'
    : 'items-center overflow-y-auto';


  const modalStyle = {
      position: 'fixed' as const,
      zIndex: 40,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  return (
    <ModalLib
      show={show}
      onHide={close}
      style={modalStyle}
      renderBackdrop={(props) => (
        <div
          {...props}
          className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300"
        />
      )}
      enforceFocus={false}
    >
     <div className={`fixed inset-0 flex justify-center z-50 ${alignClass} px-4`}>
        <div
          className={clsx(
            'bg-white rounded-xl shadow-lg w-full p-6 relative',
            sizeClass,
            className
          )}
          style={style}
        >
          {children}
          {showCloseButton &&
            [CLOSE_BUTTON_STYLE.PRIMARY, CLOSE_BUTTON_STYLE.SECONDARY].includes(closeButtonStyle) && (
              <button
                onClick={close}
                className={clsx(
                  'absolute top-4 right-4 z-50 rounded-full p-2 transition',
                  closeButtonStyle === CLOSE_BUTTON_STYLE.PRIMARY
                    ? 'bg-white text-black hover:bg-zinc-200'
                    : 'bg-zinc-800 text-white hover:bg-zinc-700'
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 8.586L5.707 4.293a1 1 0 00-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 101.414 1.414L10 11.414l4.293 4.293a1 1 0 001.414-1.414L11.414 10l4.293-4.293a1 1 0 00-1.414-1.414L10 8.586z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}

          {showCloseButton && closeButtonStyle === CLOSE_BUTTON_STYLE.FOOTER && (
            <div className="mt-6 text-right">
              <button
                onClick={close}
                className="px-4 py-2 text-sm font-medium text-white bg-zinc-700 rounded hover:bg-zinc-600"
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </ModalLib>
  );
}
