import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import Header from "../components/Header";
import Layout from "../components/Layout";
import { btn, btnPrimary } from "../ds/button.module.css";
import { WhiteArrowRight } from "../ds/Shapes";
import { Form, Input, PhoneNumberInput, TextArea } from "../myne/Form/Form";
import { CLOSE_BUTTON_STYLE, Modal, MODAL_SIZES } from "../myne/Modal/modal";

const cardData = [
  {
    logo: <StaticImage src="../images/partners/wals.png" alt="walls" />,
    description: "Walls.io is a social wall solution that helps event professionals engage their audience with user-generated content before, during and after an event.",
    link: "https://walls.io/?utm_source=premagic&utm_medium=premagicdirectory&utm_campaign=partners&utm_id=premagic",
  },
  {
    logo: <StaticImage src="../images/partners/social-wals.png" alt="Social Walls" />,
    description: "Taggbox's Social Walls is a leader in UGC curation, letting event organizers display real-time social media posts on big screens and event websites.",
    link: "https://socialwalls.com/?utm_source=socialwalls&utm_medium=referral&utm_campaign=premagic",
  },
  {
    logo: <StaticImage src="../images/partners/ticket-root.png" alt="Ticket Root" />,
    description: "Ticket Root  is an easy-to-use, mobile-first system built to manage entry, registrations, tickets, and audience preferences with innovative ticketing and POS software.",
    link: "https://ticketroot.com/",
  },
  {
    logo: <StaticImage src="../images/partners/dreamcast.png" alt="Dream Cast" />,
    description: "Dreamcast is a comprehensive event-tech suite designed for event leaders, helping to host scalable events, achieve high ROI, and deliver exceptional event experiences on the go.",
    link: "https://www.dreamcast.in/",
  },
]



export default function PartnersPage() {
  const [showModal, setShowModal] = useState(false)
  function handleEnquiry() {
    setShowModal(!showModal)
  }

  return (
    <Layout
      title="Better Events, Stronger Together"
      description="We joined forces with the leading platforms to help you plan, promote, and manage events with ease."
    >
      <div className="">
        <div className="" style={{ backgroundColor: "#7251F4" }}>
          <Header isWhiteLogo />
        </div>

        <section className="md:py-28 relative bg-[#7251F4]">
          <div className="bg-gradient-to-t from-[#7251F4] to-transparent h-64 w-full absolute bottom-0 right-0 z-10"></div>

          <div className="px-5 md:px-0 mx-auto md:w-[1080px] flex items-start pt-10 md:pt-0 md:items-center h-[calc(100dvh-80px)] md:h-[500px]">
            <div className="md:w-[600px] text-left">
              <h1 className="text-3xl md:text-6xl font-bold mb-6 text-white !leading-[1.3]">
                <span className="text-[#61F2C2]"> Better Events,</span> Stronger Together
              </h1>
              <p className="text-white text-lg mb-5">We joined forces with the leading platforms to help you plan, promote, and manage events with ease.Great events happen when the right tools come together.
              </p>
              <button
                onClick={handleEnquiry}
                className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group z-10`}
              >
                <span className="mr-4 ml-2">Get Started</span>
                <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
              </button>
            </div>
            <div className="w-11/12 md:w-6/12 absolute right-0 bottom-0 z-10 animate-[float_5s_ease-in-out_infinite]">
              <StaticImage src="../images/partners/puzzles.png" alt="AI Avatar" />
            </div>
            <div className="w-full h-full absolute bottom-0 right-0 top-0">
              <StaticImage src="../images/partners/grid.png" alt="Gird" class="h-full" />
            </div>
          </div>
        </section>
        <section className="py-16 relative ">
          <div className="px-5 md:px-0 mx-auto md:w-[1080px] text-center pt-10 md:pt-0">
            <h2 className="text-3xl md:text-5xl font-semibold mb-14 text-[#7251F4]">Our Featured Partners</h2>
          </div>
          {[
            {
              title: "Social Walls",
              data: cardData?.slice(0, 2),
            },
            {
              title: "Registration, Check-in and Badging",
              data: cardData?.slice(2, 4),
            },
          ].map((section, sectionIndex) => (
            <div key={sectionIndex} className="flex flex-col items-center justify-center px-10 mb-16">
              <h2 className="text-2xl md:text-3xl font-semibold mb-6 text-[#333] text-center">{section.title}</h2>
              <div className="flex justify-center flex-wrap gap-6">
                {section.data.map((item, index) => (
                  <div
                    key={index}
                    className="bg-white w-[400px] p-10 rounded-2xl border border-[#cbbeff] relative z-10 shadow-2xl"
                  >
                    <div className="w-[180px] md:w-[250px] mb-4">{item.logo}</div>
                    <p className="text-md md:text-lg">{item.description}</p>
                    <Link
                      to={item.link}
                      className={`${btn} ${btnPrimary} text-xs mt-5 relative w-max group z-10`}
                    >
                      Enquire
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="w-full h-full absolute bottom-0 right-0 top-0 opacity-70">
            <StaticImage src="../images/partners/grid-mesh.png" alt="Gird" />
          </div>
        </section>
        <section className="bg-[#7251F4] pb-20 pt-[150px] relative z-4 -mt-[230px] mb-10">
          <div className="px-5 md:px-0 mx-auto md:w-[1080px] text-center pt-10 md:pt-0 relative z-10">
            <h2 className="text-3xl md:text-5xl font-semibold mb-6 text-white">Become a Premagic partner</h2>
            <p className=" text-white">Let’s redefine event experiences together.</p>
            <button

              onClick={handleEnquiry}
              className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group z-10`}
            >
              <span className="mr-4 ml-2">Become a Partner</span>
            </button>
          </div>
          <div className="w-full h-full absolute bottom-0 right-0 top-0 opacity-70">
            <StaticImage src="../images/partners/round-mesh.png" alt="Mesh" class="h-full" />
          </div>
        </section>
        <Modal
          show={showModal}
          close={handleEnquiry}
          size={MODAL_SIZES.MD}
          showCloseButton
          closeButtonStyle={CLOSE_BUTTON_STYLE.PRIMARY}
        >
          <h2 className="text-xl font-bold mb-4">Enquire Now!</h2>
          <Form onSubmit={(e) => {
            e.preventDefault();
            alert("We're working on it!🙈")
          }}>
            <Input name="Name" type="text" placeholder="Full Name" />
            <Input name="email" type="email" placeholder="Email Address" />
            <PhoneNumberInput dialCode="+91" placeholder="Phone Number" />
            <TextArea name="Message" placeholder="Message" rows={4} />
            <button
              className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group`}
            >
              Submit
            </button>
          </Form>
        </Modal>
      </div>
    </Layout>
  )
}
