// components/ui/Input.tsx or InputForm.tsx (rename as needed)
import clsx from 'clsx';
import React, { FormHTMLAttributes, InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { COUNTRY_CODES } from '../../utils/CountryCodeUtils';


interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
}

export function Input({ label, error, className, ...props }: InputProps) {
  return (
    <div className="w-full">
      {label && (
        <label className="block text-sm font-medium text-zinc-700 mb-1">
          {label}
        </label>
      )}
      <input
        className={clsx(
          'w-full px-3 py-2 border rounded-md text-sm shadow-sm outline-none transition',
          'focus:ring-2 focus:ring-zinc-500 focus:border-zinc-500',
          error ? 'border-red-500' : 'border-zinc-300',
          className
        )}
        {...props}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
}

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  className?: string;
  children: React.ReactNode;
}

export function Form({ className, children, ...props }: FormProps) {
  return (
    <form
      className={clsx('space-y-4', className)}
      {...props}
    >
      {children}
    </form>
  );
}



type CountryOption = {
  label: string;
  value: string;
  dialCode: string;
  iconsSvgUrl: string;
};

type PhoneNumberInputProps = {
  dialCode?: string;
  value?: string;
  onDialCodeChange?: (code: string) => void;
  onPhoneNumberChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  error?: string;
};

export function PhoneNumberInput({
  dialCode = '+91',
  value = '',
  onDialCodeChange,
  onPhoneNumberChange,
  placeholder = 'Enter phone number',
  className,
  error,
}: PhoneNumberInputProps) {
  const [internalDialCode, setInternalDialCode] = useState(dialCode);
  const [internalValue, setInternalValue] = useState(value);

  const countryCodeOptions: CountryOption[] = useMemo(
    () =>
      COUNTRY_CODES.map((item) => ({
        label: `${item.code} (${item.dial_code})`,
        value: item.dial_code,
        dialCode: item.dial_code,
        iconsSvgUrl: `https://flagcdn.com/${item.alpha2Code.toLowerCase()}.svg`,
      })),
    []
  );

  const selectedCountryCode = useMemo(() => {
    return countryCodeOptions.find((item) => item.value === internalDialCode) || countryCodeOptions[0];
  }, [internalDialCode, countryCodeOptions]);

  useEffect(() => {
    setInternalDialCode(dialCode);
  }, [dialCode]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      border: 'none',
      boxShadow: 'none',
      background: 'transparent',
      minHeight: '42px',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      padding: '6px',
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      paddingLeft: '8px',
    }),
    option: (styles: any) => ({
      ...styles,
      padding: '10px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
    }),
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const onlyDigits = e.target.value.replace(/\D/g, '');
    setInternalValue(onlyDigits);
    onPhoneNumberChange?.(onlyDigits);
  };

  const handleDialCodeChange = (option: CountryOption | null) => {
    if (option) {
      setInternalDialCode(option.value);
      onDialCodeChange?.(option.value);
    }
  };

  return (
    <div className={clsx('w-full', className)}>
      <div
        className={clsx(
          'flex items-center w-full rounded-md border text-sm shadow-sm transition',
          'focus-within:ring-2 focus-within:ring-zinc-500 focus-within:border-zinc-500',
          error ? 'border-red-500' : 'border-zinc-300'
        )}
      >
        <div className="w-36">
          <Select
            value={selectedCountryCode}
            options={countryCodeOptions}
            onChange={handleDialCodeChange}
            styles={colourStyles}
            classNamePrefix="react-select"
            formatOptionLabel={(e: CountryOption) => (
              <div className="flex items-center gap-2">
                <img src={e.iconsSvgUrl} alt={e.label} className="w-5 h-4 object-cover rounded-sm" />
                <span>{e.label}</span>
              </div>
            )}
          />
        </div>
        <input
          type="tel"
          value={internalValue}
          onChange={handlePhoneNumberChange}
          className="w-full px-3 py-2 outline-none bg-transparent"
          placeholder={placeholder}
          inputMode="numeric"
        />
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
}


interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  className?: string;
}

export function TextArea({ label, error, className, ...props }: TextAreaProps) {
  return (
    <div className="w-full">
      {label && (
        <label className="block text-sm font-medium text-zinc-700 mb-1">
          {label}
        </label>
      )}
      <textarea
        {...props}
        className={clsx(
          'w-full px-3 py-2 border rounded-md text-sm shadow-sm outline-none transition resize-none',
          'focus:ring-2 focus:ring-zinc-500 focus:border-zinc-500',
          error ? 'border-red-500' : 'border-zinc-300',
          className
        )}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
}
